// import Modal from 'react-bootstrap/Modal';
// import ModalImage from 'react-modal-image';

// const ViewDetails = ({show, handleClose, item}) => {
//   if (!item) return null; // Return null to render nothing if there is no item.
//   console.log('viewdetail', item);
//   return (
//     <Modal show={show} onHide={handleClose} size='lg'>
//       <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-1 px-5'>
//         <Modal.Title className='px-0'>
//           <div className='titleFont'>Clinic Information</div>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body style={{background: '#F2F3F7', padding: '5px 12px'}}>
//         <div
//           className='d-flex gap-7'
//           style={{background: 'white', borderRadius: '8px', padding: '10px'}}
//         >
//           {/* Left Section: Clinic Information */}
//           <div className='formLabelTitle'>
//             <div className='sectionTitle'>Clinic Information:</div>
//             {/* <img
//               src={item.clinicLogo || '/default-logo.png'}
//               alt={`${item.clinicName || 'Default'} Clinic Logo`}
//               className="clinic-logo"
//             /> */}
//             <ModalImage
//               small={item.clinicLogo}
//               large={item.clinicLogo}
//               alt=''
//               hideDownload={true} // Optionally hide download button
//               hideZoom={true} // Optionally hide zoom button
//             />
//             <p>
//               <strong>Clinic Name:</strong> {item.clinicName}
//             </p>
//             <p>
//               <strong>Clinic Website:</strong> {item.clinicWebsite}
//             </p>
//             <p>
//               <strong>Address:</strong> {`${item.addressLine1},${item.addressLine2 || ''},${item.cityName} , ${item.stateName}, ${item.countryName},${item.zipCode}`}
//             </p>
//             <p>
//               <strong>Group ID:</strong> {item.groupClinicDetails.clinicName}
//             </p>
//           </div>

//           {/* Right Section: Contact Person Information */}
//           <div className='formLabelTitle'>
//             <div className='sectionTitle'>
//               <strong>Contact Person:</strong>
//             </div>
//             <p>
//               <strong>Full Name:</strong>{' '}
//               {`${item.firstName} ${item.middleName || ''} ${item.lastName}`}
//             </p>
//             <p>
//               <strong>Email:</strong> {item.email}
//             </p>
//             <p>
//               <strong>Cell Phone No.:</strong> {item.contactNumber1}
//             </p>
//             <p>
//               <strong>Work Phone No.:</strong> {item.contactNumber2}
//             </p>
//             <p>
//               <strong>Ext:</strong> {item.ext || 'N/A'}
//             </p>
//             <p>
//               <strong>Refer by:</strong> {item.referBy || 'N/A'}
//             </p>
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer style={{background: '#F2F3F7'}} className='p-2'>
//         <button className='tertiarybig' onClick={handleClose}>
//           Close
//         </button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default ViewDetails;

import Modal from 'react-bootstrap/Modal';
import ModalImage from 'react-modal-image';
import TitleComponent from '../../../ReUsableComponent/TitleComponent';
import { CommonbigButton } from '../ReausableComponent/CommonButton';

const ViewDetails = ({show, handleClose, item}) => {
  if (!item) return null; // Return null to render nothing if there is no item.

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-1 px-3'>
        <Modal.Title className='px-0'>
          <TitleComponent type='title'>Clinic Information</TitleComponent>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{background: '#F2F3F7', padding: '5px 12px'}}>
        <div
          className='d-flex gap-7'
          style={{background: 'white', borderRadius: '8px', padding: '10px'}}
        >
          {/* Left Section: Clinic Information */}
          <div className='d-flex'>
          <div>
            <TitleComponent type='sectionTitle'>Clinic Information:</TitleComponent>
            <ModalImage
              small={item?.clinicLogo || '/default-logo.png'}
              large={item?.clinicLogo || '/default-logo.png'}
              alt={item?.clinicName || 'Default Clinic Logo'}
              hideDownload={true} // Optionally hide download button
              hideZoom={true} // Optionally hide zoom button
            />
          </div>
          <div>

            <TitleComponent type='label' className='px-1 '>
              <strong>Clinic Name:</strong> {item?.clinicName || 'N/A'}
            </TitleComponent>
            <TitleComponent type='label' className='px-1 '>
              <strong>Clinic Website:</strong> {item?.clinicWebsite || 'N/A'}
            </TitleComponent>
         
          <TitleComponent type='label' className='px-1 '>
            <strong>Address:</strong>{' '}
            {`${item?.addressLine1 || ''}, ${item?.addressLine2 || ''}, ${item?.cityName || ''}, ${
              item?.stateName || ''
            }, ${item?.countryName || ''}, ${item?.zipCode || ''}`}
          </TitleComponent>
          </div>
          </div>
          {/* Right Section: Contact Person Information */}
          <div className='formLabelTitle gap-1'>
            <TitleComponent type='sectionTitle' className='px-1 '>
              <strong>Contact Person:</strong>
            </TitleComponent>
            <div className='d-flex'>
              <TitleComponent type='label' className='px-1 '>
                <strong>Full Name:</strong>{' '}
                {`${item?.firstName || ''} ${item?.middleName || ''} ${item?.lastName || ''}`}
              </TitleComponent>
              <TitleComponent type='label' className='px-1 '>
                <strong>Email:</strong> {item?.email || 'N/A'}
              </TitleComponent>
            </div>
            <div className='d-flex'>
              <TitleComponent type='label' className='px-1 '>
                <strong>Cell Phone No.:</strong> {item?.contactNumber1 || 'N/A'}
              </TitleComponent>
              <TitleComponent type='label' className='px-1 '>
                <strong>Work Phone No.:</strong> {item?.contactNumber2 || 'N/A'}
              </TitleComponent>
              <TitleComponent type='label' className='px-1 '>
                <strong>Ext:</strong> {item?.ext || 'N/A'}
              </TitleComponent>
            </div>
            <div className='d-flex'>
              <TitleComponent type='label' className='px-1 '>
                <strong>Refer by:</strong> {item?.referBy || 'N/A'}
              </TitleComponent>
              <TitleComponent type='label' className='px-1 '>
                <strong>Group ID:</strong> {item?.groupName || 'N/A'}
              </TitleComponent>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{background: '#F2F3F7'}} className='p-2'>
      <CommonbigButton background={'#D7D7D7'} color={'#818181'} onClick={handleClose}>
          Close
        </CommonbigButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewDetails;

import React, { useEffect, useState } from 'react'
import AddNewDocument from './ReusablePatients/AddNewDocument';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import ConvertTo12Hour from '../../../ReUsableComponent/ConvertTo12Hour';
import EditDocument from './ReusablePatients/EditDocument';

function PatientDocuments({patientData}) {

  const APIkey = process.env.REACT_APP_SERVERAPI;

  const {id} = useParams(); 
    
    const [AddDocumentPopUp, setAddDocumentPopUp] = useState<any>(false);
    const [EditDocumentPopUp , setOpenDocumentPopUp ] = useState<any>(false);

    const [documentData, setDocumentData] = useState<any>([]);

    const [ editdocumentId , setEditdocumentId ] = useState<any>();

    useEffect(()=> {
        const getAllDocuments = async()=> {
            try {
                let getloginInfo: string | null = localStorage.getItem('appLoginInfo')
        
                if (getloginInfo !== null) {
        
                    const Token = JSON.parse(getloginInfo)?.data?.accessToken;
        
                    const Id = JSON.parse(getloginInfo).data.clinicId;
        
                    const userId =  JSON.parse(getloginInfo).data.id ;
        
                    const header = {
                        headers: { 'x-access-token': Token }
                    }

                    const getallDouments = await axios.get(`${APIkey}/api/admin/getPatientDocumentsList` , header )

                    console.log(getallDouments?.data.data);
                    

                   setDocumentData(getallDouments?.data?.data?.filter((i)=> i?.patientId == id));

                    
                }
                else {
                    console.log("No login info found in localStorage");
                }
            }
            catch (err) {
                console.log(err);
            }
        }

        getAllDocuments();
    },[AddDocumentPopUp , EditDocumentPopUp])



     


    const OpenPopUpFunction = () => {
        setAddDocumentPopUp(true)
    }

    const closeDocumentPopUp = () => {
        setAddDocumentPopUp(false)
        setOpenDocumentPopUp(false)
    }

    return (
        <div className='card  '>
            <div className='d-flex gap-2 px-2 pt-2 '>
               
           
            <div className=''>
                <div className='pb-0 mb-5 px-2 mt-2'>
                    <div className='titleFont'>Uploaded Documents</div>
                </div>
            </div>
            <div>
                    <button
                        className='blueButton mt-2'
                        onClick={OpenPopUpFunction}
                    >
                        <i className="fa-solid fa-plus "></i>
                        <span>
                            Add Files
                        </span>
                    </button>
                </div>
                </div>
            <div className='w-100 p-2'>
                <table className='w-100 fullCurve' >
                    <thead >
                        <tr className='tableHead'>
                            <th>
&nbsp;
                            </th>
                            <th>
                            Document Type
                            </th>
                            <th>
                            Document Name
                            </th>
                            <th>
                            Description
                            </th>
                            <th>
                            Visit Date
                            </th>
                            <th>
                            Uploaded On
                            </th>
                            <th>
                            Time
                            </th>
                            <th>
                            Uploaded By
                            </th>
                            <th>
                            Status   
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            documentData?.map((i: any , index) => {

                                // const getTheIdData = (id: any) => {
                                //     console.log("this is ID : " , id);
                                //     if(idData == id){
                                //         setIdData('')
                                //     }
                                //     else{
                                //         setIdData(id)
                                //     }
                                // }

                                // if (idData == i?._id) {
                                //     return (
                                //         <>
                                //             <tr className='tableBody'>
                                //                 <td>
                                //                     <button onClick={() => getTheIdData(i?._id)}>
                                //                         icon
                                //                     </button>
                                //                 </td>
                                //                 <td>

                                //                 </td>
                                //                 <td>

                                //                 </td>
                                //                 <td>

                                //                 </td>
                                //                 <td>

                                //                 </td>
                                //                 <td>

                                //                 </td>
                                //                 <td>

                                //                 </td>
                                //                 <td>

                                //                 </td>
                                //                 <td>

                                //                 </td>

                                //                 <td>
                                //                     <div className='d-flex align-items-center gap-2 my-1'>
                                //                         <button className='blueButton'>
                                //                             <i className="fa-solid fa-pen"></i>
                                //                         </button>
                                //                         <button className='blueButton'>
                                //                             <i className="fa fa-trash" aria-hidden="true"></i>
                                //                         </button>
                                //                     </div>
                                //                 </td>
                                //             </tr>

                                //             <tr  className=''> 
                                //             <td colSpan={10} className=''>
                                //                 <div className='d-flex justify-content-center'>

                                //                 <span>{idData}</span>
                                //                 </div>
                                //                 </td>
                                //             </tr>

                                //         </>

                                //     )
                                // }
                                // else {

                                const formateDate =(date)=> date == null ? "" :  moment(date).format('MM/DD/yyyy') 

                                
                                    return (
                                        <>
                                            <tr key={index} className='tableBody'>
                                                <td>
                                               
                                                </td>
                                                <td>
                                                    {i?.documenttype?.documentTypeName}
                                                </td>
                                                <td>
                                                {i?.documentName}  
                                                </td>
                                                <td>
                                                      {i?.description}
                                                </td>
                                                <td>
                                                    {formateDate(i?.visitDate)}
                                                </td>
                                                <td>
                                                    {formateDate(i?.createdDate)}
                                                </td>
                                                <td>
                                                    {ConvertTo12Hour( i?.visitTime ) || ''}
                                                </td>
                                                <td>
                                                    {
                                                        i?.updatedBy ? 
                                                        `${i?.uploadedby?.firstName} ${i?.uploadedby?.lastName}`
                                                         :
                                                        `${i?.uploadedby?.firstName} ${i?.uploadedby?.lastName}`
                                                    }
                                                </td>
                                                <td>
                                                    {i?.documentstatus?.documentStatusName}
                                                </td>

                                                <td  className='d-flex align-items-center gap-2 py-1'>
                                                    
                                                        <button className='blueButtonSmall' onClick={()=> [setOpenDocumentPopUp(true) , setEditdocumentId(i?._id)]}>
                                                            <i className="fa-solid fa-pen"></i>
                                                        </button>
                                                        {/* <button className='blueButtonSmall'>
                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                        </button> */}
                                                    
                                                </td>
                                            </tr>


                                        </>

                                    )
                                // }



                            })
                        }

                    </tbody>
                </table>
            </div>

            {
                AddDocumentPopUp &&
                <AddNewDocument
                    disabled={true}
                    open={AddDocumentPopUp}
                    closeDocumentPopUp={closeDocumentPopUp}
                    patientData={patientData}
                />
            }

            {
                EditDocumentPopUp &&
                <EditDocument
                   disabled={true}
                    open={EditDocumentPopUp}
                    documentId = {editdocumentId}
                    closeDocumentPopUp={closeDocumentPopUp}
                    patientData={patientData}
                />
            }




                <ToastContainer />
        </div>



    )
}

export default PatientDocuments

import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ReactPaginate from 'react-paginate'
import 'font-awesome/css/font-awesome.min.css' // You may need to adjust the path

import inputText from "../../../ReUsableComponent/ReyClinicalinputs.json"
import MultiSelectDropdown from '../ReusablePages/ReusableAppointment/MultiSelectDropdown'
import SerchableDropdown from '../ReusablePages/ReusableAppointment/SearchableDropdown'
import PhoneInputCompo from '../../../ReUsableComponent/PhoneInputCompo'
import axios from 'axios'
import GenderComponent from '../../../ReUsableComponent/GenderComponent'
import {
  SSNRegExp,
  nameRegEx,
  AddressRegExp,
  ZipRegEx,
  EmailRegExp,
  phoneNoRegexp,
  extNoRegExp
} from '../../../ReUsableComponent/regEx'

import { Formik, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import validation from '../../../ReUsableComponent/validation'
import MaskedInput from 'react-text-mask'
import { toast } from 'react-toastify'
import Datepicker from '../../../ReUsableComponent/Datepicker'




function AddNewPatient({ open, closeAddNewPatient }) {

  const APIkey = process.env.REACT_APP_SERVERAPI;

  const [showInsuranceClickPopup, setShowInsuranceClickPopup] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const [showPrimaryGuarantorClickPopup, setShowPrimaryGuarantorClickPopup] = useState(false)
  const [searchPrimaryGuarantorQuery, setSearchPrimaryGuarantorQuery] = useState('')

  const [ loading , setLoading  ] = useState(false)



  const insuranceClickSearchClick = () => {
    setShowInsuranceClickPopup(true)
  }

  const handleCloseInsurancePlanPopup = () => {
    setShowInsuranceClickPopup(false)
  }

  const primaryGuarantorClickSearchClick = () => {
    setShowPrimaryGuarantorClickPopup(true)
  }

  const handleCloseprimaryGuarantorPopup = () => {
    setShowPrimaryGuarantorClickPopup(false)
  }

  const [insuranceCompanyType, setInsuranceType] = useState([
    {
      id: '',
      insuranceName: 'SureGuard Health Insurance',
      payerID: '012345',
      address: '',
      city: '',
      state: 'AL',
      zip: '',
      phone: '',
      insuranceType: 'Other'
    },
    {
      id: '',
      insuranceName: 'LibertyLife Assurance',
      payerID: '012345',
      address: '',
      city: '',
      state: 'AK',
      zip: '',
      phone: '',
      insuranceType: 'Other'
    },
    {
      id: '',
      insuranceName: 'GoldenShield Insurance',
      payerID: '012345',
      address: '',
      city: '',
      state: 'AZ',
      zip: '',
      phone: '',
      insuranceType: 'Other'
    },
    {
      id: '',
      insuranceName: 'SafeHarbor Health Plan',
      payerID: '012345',
      address: '',
      city: '',
      state: 'GA',
      zip: '',
      phone: '',
      insuranceType: 'Other'
    },
    {
      id: '',
      insuranceName: 'SecureHealth Group',
      payerID: '012345',
      address: '',
      city: '',
      state: 'IN',
      zip: '',
      phone: '',
      insuranceType: 'Other'
    },
    {
      id: '',
      insuranceName: 'PinnacleCare Insurance',
      payerID: '012345',
      address: '',
      city: '',
      state: 'MI',
      zip: '',
      phone: '',
      insuranceType: 'Other'
    },
    {
      id: '',
      insuranceName: 'UnitedWell Assurance',
      payerID: '012345',
      address: '',
      city: '',
      state: 'NM',
      zip: '',
      phone: '',
      insuranceType: 'Other'
    },
    {
      id: '',
      insuranceName: 'GuardianLife Benefits',
      payerID: '012345',
      address: '',
      city: '',
      state: 'UT',
      zip: '',
      phone: '',
      insuranceType: 'Other'
    },
    {
      id: '',
      insuranceName: 'WellBeing Insurance',
      payerID: '012345',
      address: '',
      city: '',
      state: 'UT',
      zip: '',
      phone: '',
      insuranceType: 'Tricare Champus'
    },
    {
      id: '',
      insuranceName: 'TotalCare Assurance',
      payerID: '012345',
      address: '',
      city: '',
      state: 'UT',
      zip: '',
      phone: '',
      insuranceType: 'Medicaid'
    },
  ])

  const [primaryGuarantorData, setPrimaryGuarantor] = useState([
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'Emily Davis A.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'F',
      insuranceName: 'SureGuard Health Insurance',

    },
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'Michael Johnson A.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'M',
      insuranceName: 'LibertyLife Assurance',

    },
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'Sarah Thompson A.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'F',
      insuranceName: 'SafeHarbor Health Plan',

    },
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'David Wilson P.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'M',
      insuranceName: 'GoldenShield Insurance',

    },
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'Linda Brown S.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'F',
      insuranceName: 'SecureHealth Group',

    },
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'Jennifer Turner T.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'M',
      insuranceName: 'PinnacleCare Insurance',

    },
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'Jennifer Turner T.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'F',
      insuranceName: 'UnitedWell Assurance',

    },
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'William White R.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'M',
      insuranceName: 'GuardianLife Benefits',

    },
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'Mary Roberts O.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'F',
      insuranceName: 'WellBeing Insurance',

    },
    {
      id: '',
      patientID: '1246922704',
      patAcctNo: '',
      name: 'James Anderson E.',
      homePhone: '(555) 444-5678',
      dateOfBirth: '06/25/1985',
      sex: 'M',
      insuranceName: 'TotalCare Assurance',

    },
  ])


  const [currentPage, setCurrentPage] = useState(0)
  const insurancePerPage = 5
  const primaryGuarantorPerPage = 5

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected)
  }

  const filteredInsuranceCompanyType = insuranceCompanyType.filter((insuranceCompanyData) =>
    insuranceCompanyData.insuranceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    insuranceCompanyData.payerID.toLowerCase().includes(searchQuery.toLowerCase()) ||
    insuranceCompanyData.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
    insuranceCompanyData.zip.toLowerCase().includes(searchQuery.toLowerCase()) ||
    insuranceCompanyData.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
    insuranceCompanyData.insuranceType.toLowerCase().includes(searchQuery.toLowerCase()) ||
    insuranceCompanyData.state.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const indexOfLastInsuranceCompany = (currentPage + 1) * insurancePerPage
  const indexOfFirstInsuranceCompany = indexOfLastInsuranceCompany - insurancePerPage
  const currentInsuranceCompany = filteredInsuranceCompanyType.slice(
    indexOfFirstInsuranceCompany,
    indexOfLastInsuranceCompany
  )


  const filteredPrimaryGuarantor = primaryGuarantorData.filter((primaryGuarantor) =>
    primaryGuarantor.patientID.toLowerCase().includes(searchPrimaryGuarantorQuery.toLowerCase()) ||
    primaryGuarantor.patAcctNo.toLowerCase().includes(searchPrimaryGuarantorQuery.toLowerCase()) ||
    primaryGuarantor.name.toLowerCase().includes(searchPrimaryGuarantorQuery.toLowerCase()) ||
    primaryGuarantor.homePhone.toLowerCase().includes(searchPrimaryGuarantorQuery.toLowerCase()) ||
    primaryGuarantor.dateOfBirth.toLowerCase().includes(searchPrimaryGuarantorQuery.toLowerCase()) ||
    primaryGuarantor.sex.toLowerCase().includes(searchPrimaryGuarantorQuery.toLowerCase()) ||
    primaryGuarantor.insuranceName.toLowerCase().includes(searchPrimaryGuarantorQuery.toLowerCase())
  );


  const indexOfLastPrimaryGuarantor = (currentPage + 1) * primaryGuarantorPerPage
  const indexOfFirstPrimaryGuarantor = indexOfLastPrimaryGuarantor - primaryGuarantorPerPage
  const currentPrimaryGuarantor = filteredPrimaryGuarantor.slice(
    indexOfFirstPrimaryGuarantor,
    indexOfLastPrimaryGuarantor
  )

  // const [Referral, setReferral] = useState()






  // Add new patient  Functionality Start here ...




  const [formValues, setFormValues] = useState([])

  const [contryOption, setCountryOption] = useState([]);
  const [stateOPtion, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);


  const [lastName, setLastName] = useState<string | null>();
  const [firstName, setFirstName] = useState<string | null>();
  const [middleName, setMiddleName] = useState<string | null>();
  const [ssn, setSSN] = useState<string | null>();
  const [genderId, setSex] = useState<String | null>();
  const [dob, setDob] = useState<Date | null>();
  const [addressLine1, setAddress1] = useState<string | null>();
  const [addressLine2, setAddress2] = useState<string | null>();
  const [cityId, setCity] = useState<string | null>();
  const [stateId, setState] = useState<string | null>();
  const [countryId, setContry] = useState<string | null>();
  const [zipcodeId, setZip] = useState<any>();
  const [emailAddress, setEmail] = useState<string | null>();
  const [mobileCountryCode, setCountrycode1] = useState<string | null>();
  const [mobileNo, setCellNo] = useState<string | null>();
  const [homeCountryCode, setCountrycode2] = useState<string | null>();
  const [homeNo, setHomeNo] = useState<string | null>();
  const [workCountryCode, setCountrycode3] = useState<string | null>();
  const [workPhoneNo, setWorkNo] = useState<string | null>();
  const [workExtNo, setExt] = useState<any>();

  const [referral, setReferral] = useState<any>([]);

  const [field1, setField1] = useState();
  const [field2, setField2] = useState();
  const [field3, setField3] = useState();
  const [field4, setField4] = useState();
  const [field5, setField5] = useState();
  const [field6, setField6] = useState();
  const [field7, setField7] = useState();
  const [field8, setField8] = useState();


  const [errLastName, setErrLastName] = useState('')
  const [errFirstName, setErrFirstName] = useState('')
  const [errMiddleName, setErrMiddleName] = useState('')
  const [errSSN, setErrSSN] = useState('')
  const [errGender, setErrGender] = useState('')
  const [errDob, setErrDOb] = useState('')
  const [errAddressLine1, setErrAddressLine1] = useState('')
  const [errAddressLine2, setErrAddressLine2] = useState('')
  const [errCity, setErrCity] = useState('')
  const [errState, setErrState] = useState('')
  const [errcountry, setErrcountry] = useState('')
  const [errZipcode, setErrZipcode] = useState('')
  const [errEmail, setErrEmail] = useState('')
  const [errCellPhone, setErrCellPhone] = useState('')
  const [errHomePhone, setErrHomePhone] = useState('')
  const [errWorkPhone, setErrWorkPhone] = useState('')
  const [errWorkExt, setErrWorkExt] = useState('')
  const [errField1, setErrField1] = useState('')
  const [errField2, setErrField2] = useState('')
  const [errField3, setErrField3] = useState('')
  const [errField4, setErrField4] = useState('')
  const [errField5, setErrField5] = useState('')
  const [errField6, setErrField6] = useState('')
  const [errField7, setErrField7] = useState('')
  const [errField8, setErrField8] = useState('')

  const [errors, setErrors] = useState({});




  useEffect(() => {




    const getTheCountry = async () => {
      try {
        const response = await axios.get(`${APIkey}/api/admin/getCountryList`);
        setCountryOption(response.data.data.map((i) => ({
          label: i.name,
          value: i.name,
          id: i.id
        })));

        // const countryRes = await axios.get(`${APIkey}/api/admin/getStateList/${countryid}`);
        // setStateOption(countryRes.data.data.map((i) => ({
        //     label: i.name,
        //     value: i.name,
        //     id: i.id
        // })));

        // const cityRes = await axios.get(`${APIkey}/api/admin/getCityList/${stateId}`);


        // setCityOption(cityRes.data.data.map((i) => ({
        //     label: i.name,
        //     value: i.name,
        //     id: i.id
        // })));
      } catch (err) {
        console.log(err);
      }
    }
    getTheCountry();




  }, [])


  const handelClose = () => {
    closeAddNewPatient()
  }



  const getTheLastName = (e) => {
    setLastName(e.target.value);

  }
  const getThefirstName = (e) => {
    setFirstName(e.target.value);

  }
  const getTheMiddleName = (e) => {
    setMiddleName(e.target.value);
  }
  const getTheSSN = (e) => {

    const value = e.target.value.replace(/\D/g, '');
    setSSN(value);

  }

  const getTheSex = (e) => {
    setSex(e);
  }
  const getTheDOB = (e) => {
    setDob(e.target.value);
  }
  const getTheAddress1 = (e) => {
    setAddress1(e.target.value);
  }
  const getTheAddress2 = (e) => {
    setAddress2(e.target.value);
  }
  const getTheCity = (e) => {
    setCity(e);
  }
  const getTheState = async (e) => {
    setState(e);
    try {
      const response = await axios.get(`${APIkey}/api/admin/getCityList/${e}`)

      setCityOption(response.data.data.map((i) => (
        {
          label: i.name,
          value: i.name,
          id: i.id
        }
      )));

    }
    catch (err) {
      console.log(err);

    }
  }
  const getTheCountry = async (e) => {
    setContry(e);
    try {
      const response = await axios.get(`${APIkey}/api/admin/getStateList/${e}`)

      setStateOption(response.data.data.map((i) => (
        {
          label: i.name,
          value: i.name,
          id: i.id
        }
      )));

    }
    catch (err) {
      console.log(err);

    }
  }

  const getTheZip = (e) => {

    // setZip(e.target.value);
    const maxLength = 6; // Maximum number of digits allowed
    let value = e.target.value; // Get the value entered by the user

    // If the length of the value exceeds the maximum length, trim it
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }

    // Set the state with the trimmed value
    setZip(value);

  }

  const getTheEmail = (e) => {
    setEmail(e.target.value);
  }
  const getTheCellNO = (e) => {
    setCountrycode1(e.gettheCode);
    setCellNo(e.getTheNumber)
  }

  const getTheHomeNo = (e) => {
    setCountrycode2(e.gettheCode);
    setHomeNo(e.getTheNumber)
  }
  const getTheWorkNO = (e) => {
    setCountrycode3(e.gettheCode);
    setWorkNo(e.getTheNumber)

  }

  const getTheExt = (e) => {
    const maxLength = 4; // Maximum number of characters allowed
    let value = e.target.value; // Get the value entered by the user
    
    // If the length of the value exceeds the maximum length, trim it
    if (value.length > maxLength) {
        value = value.slice(0, maxLength);
    }

    // Set the state with the trimmed value
    setExt(value);
  }
  const getTheRefferal = (e) => {
    // setReferral(e)
    setReferral(e);
    console.log(e);
    

  }
  const gettheField1 = (e) => {
    setField1(e.target.value)
  }
  const gettheField2 = (e) => {
    setField2(e.target.value)
  }
  const gettheField3 = (e) => {
    setField3(e.target.value)
  }
  const gettheField4 = (e) => {
    setField4(e.target.value)
  }
  const gettheField5 = (e) => {
    setField5(e.target.value)
  }
  const gettheField6 = (e) => {
    setField6(e.target.value)
  }
  const gettheField7 = (e) => {
    setField7(e.target.value)
  }
  const gettheField8 = (e) => {
    setField8(e.target.value)
  }







  const multieoptions = [
    {
      value: "Emma Johnson",
      label: "Emma Johnson",
      id : "62159d56cf43e6091feef996"
    },
    {
      value: "Ethan Williams",
      label: "Ethan Williams",
      id : "62159d56cf43e6091feef997"
    },
    {
      value: "Olivia Martinez",
      label: "Olivia Martinez" , 
      id : "62159d56cf43e6091feef998"
    },
    {
      value: "Noah Taylor",
      label: "Noah Taylor", 
      id : "62159d56cf43e6091feef992"
    }
  ]


  const addPatient = async (data) => {

    try {
      let getloginInfo: string | null = localStorage.getItem('appLoginInfo')

      if (getloginInfo !== null) {

        const Token = JSON.parse(getloginInfo).data.accessToken;

        const header = {
          headers: { 'x-access-token': Token }
        }

        const response = await axios.post(`${APIkey}/api/admin/createPatients`, data, header);

        console.log('Response from server: ', response);

        if (response) {
          setLoading(false)
          // Handle successful response
          console.log('Patient added successfully');

          handelClose();

          toast.success('Patient added successfully!', {
            position: 'top-right'
          });

        }
      } else {
        console.log('No access token found in localStorage');
      }
    } catch (error) {
      // Handle errors
      // setLoading(false)
      console.error('Error occurred while adding patient: ', error);
    }
  };





  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true)

    let patientData = {
      lastName,
      firstName ,
      middleName,
      ssn,
      genderId,
      dob,
      addressLine1,
      addressLine2,
      countryId,
      stateId,
      cityId,
      zipcodeId,
      emailAddress,

      mobileCountryCode,
      mobileNo,

      homeCountryCode,
      homeNo,

      workCountryCode,
      workPhoneNo,
      workExtNo,
      referral ,
      field1,
      field2,
      field3,
      field4,
      field5,
      field6,
      field7,
      field8
    }



    let err = validation(patientData);



    if (Object.keys(err).length === 0) {

      setErrLastName(err.lastName);
      setErrFirstName(err.firstName);
      setErrMiddleName(err.middleName);
      setErrSSN(err.ssn)
      setErrGender(err.gender)
      setErrAddressLine1(err.addressLine1)
      setErrAddressLine2(err.addressLine2)
      setErrcountry(err.countryId)
      setErrState(err.stateId)
      setErrCity(err.cityId)
      setErrDOb(err.dob)
      setErrZipcode(err.zipcodeId)
      setErrEmail(err.emailAddress)
      setErrCellPhone(err.mobileNo)
      setErrHomePhone(err.homeNo)
      setErrWorkPhone(err.workPhoneNo)
      setErrWorkExt(err.workExtNo)

      console.log("form Submitted ...");
      
      addPatient(patientData)


      // Add your form submission logic here

    } else {

      setLoading(false)
      setErrLastName(err.lastName);
      setErrFirstName(err.firstName);
      setErrMiddleName(err.middleName);
      setErrSSN(err.ssn)
      setErrGender(err.gender)
      setErrDOb(err.dob)
      setErrAddressLine1(err.addressLine1)
      setErrAddressLine2(err.addressLine2)
      setErrcountry(err.countryId)
      setErrState(err.stateId)
      setErrCity(err.cityId)
      setErrZipcode(err.zipcodeId)
      setErrEmail(err.emailAddress)
      setErrCellPhone(err.mobileNo)
      setErrHomePhone(err.homeNo)
      setErrWorkPhone(err.workPhoneNo)
      setErrWorkExt(err.workExtNo)

    }

  }

  return (
    <>
      <Modal show={open} onHide={handelClose} size='xl' centered>
      <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-1 px-5'>
      <Modal.Title className='px-0'><div className='titleFont'>Add New Patient</div></Modal.Title>
        </Modal.Header>
        <Modal.Body className='model-footer-color px-5 py-5'>

          <Form>
            <div className='patient-info-card '>
              <Row className='pmrt'>
                <Col md={12}>
                  <label className='sectionTitle p-2'>Patient Information</label>
                  {/* <hr className='lineBottom' /> */}
                </Col>
              </Row>

              <Row className='row-width'>
                <Col className='first-Input col-width '>
                  <label className='required patient-info-title'>{inputText.lastName.label}</label>
                  <input
                    type='text'
                    onChange={getTheLastName}
                    maxLength={50}
                    className='form-control SearchInputbox'
                    name='lastName'
                    placeholder=''
                  />
                  {errLastName && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errLastName}
                    </span>
                  )}
                </Col>

                <Col className='col-width'>
                  <label className='required patient-info-title'>{inputText.firstName.label}</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    maxLength={50}
                    onChange={getThefirstName}
                    name='lastName'
                    placeholder=''
                  />
                  {errFirstName && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errFirstName}
                    </span>
                  )}
                </Col>

                <Col className='col-width'>
                  <label className=' patient-info-title'>{inputText.MiddleName.label}</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    onChange={getTheMiddleName}
                    maxLength={50}
                    name='MiddleName'
                    placeholder=' '
                  />
                  {errMiddleName && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errMiddleName}
                    </span>
                  )}
                </Col>

                <Col className='col-width'>
                  <label className='required patient-info-title'>{inputText.SSN.label}</label>
                  <MaskedInput
                    mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    className='form-control SearchInputbox'
                    onChange={getTheSSN}
                    value={ssn || ''}
                    placeholder={inputText.SSN.placeholder}
                  />
                  {errSSN && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errSSN}
                    </span>
                  )}

                </Col>
              </Row>

              <div>

              </div>


              <Row className='row-width'>
                <Col className='first-Input col-width'>
                  <label className='required patient-info-title'>{inputText.Sex.label}</label>
                  <GenderComponent getTheGender={getTheSex} genderValue={genderId} />
                  {errGender && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errGender}
                    </span>
                  )}
                </Col>

                <Col className='col-width'>
                  <label className=' patient-info-title required'>{inputText.dateOfBirth.label}</label>
                  <input
                    type='date'
                    onChange={getTheDOB}
                    className='form-control SearchInputbox' />
                         {/* <Datepicker 
                      selectedDate={dob || ''}
                      gettingDate={getTheDOB}
                      disabled={false}
                      /> */}
                  {errDob && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errDob}
                    </span>
                  )}
                </Col>
                <Col className='col-width'></Col>
                <Col className='col-width'></Col>
              </Row>
            </div>

            <div className='patient-info-card'>
              <hr className='lineBottom' />
              <Row className='pmrt'>
                <Col md={12}>
                  <label className='sectionTitle p-2'>Contact Information</label>
                  {/* <hr className='lineBottom' /> */}
                </Col>
              </Row>

              <Row className='row-width'>
                <Col className='first-Input col-width'>
                  <label className='patient-info-title required'>{inputText.Address1.label}</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    onChange={getTheAddress1}
                    maxLength={100}
                    name='address1'
                    placeholder=' '
                  />
                  {errAddressLine1 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errAddressLine1}
                    </span>
                  )}
                </Col>

                <Col className='col-width'>
                  <label className='patient-info-title'>{inputText.Address2.label}</label>
                  <input
                    type='text'
                    onChange={getTheAddress2}
                    maxLength={100}
                    className='form-control SearchInputbox'
                    name='address2'
                    placeholder=' '
                  />
                  {errAddressLine2 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errAddressLine2}
                    </span>
                  )}
                </Col>
                <Col className='  col-width'>
                  <label className='patient-info-title required'>{inputText.country.label}</label>
                  <SerchableDropdown
                  InitialValue={countryId}
                    options={contryOption}
                    gettingData={getTheCountry}
                  />
                  {errcountry && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errcountry}
                    </span>
                  )}
                </Col>



                <Col className='col-width'>
                  <label className='patient-info-title required'>{inputText.state.label}</label>
                  <SerchableDropdown
                  InitialValue={stateId}
                    options={stateOPtion}
                    gettingData={getTheState}
                  />
                  {errState && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errState}
                    </span>
                  )}
                </Col>
              </Row>

              <Row className='row-width'>

                <Col className='first-Input col-width'>
                  <label className='patient-info-title required'>{inputText.city.label}</label>
                  <SerchableDropdown
                  InitialValue={cityId}
                    options={cityOption}
                    gettingData={getTheCity}
                  />

                  {errCity && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errCity}
                    </span>
                  )}
                </Col>

                <Col className=' col-width'>
                  <label className='patient-info-title required'>{inputText.zip.label}</label>
                  <input
                    type='number'
                    className='form-control SearchInputbox'
                    name='zip'
                    maxLength={6}
                    value={zipcodeId}
                    onChange={getTheZip}
                    placeholder=''
                  />
                  {errZipcode && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errZipcode}
                    </span>
                  )}
                </Col>
                <Col className='col-width'>
                  <label className='patient-info-title required'>{inputText.email.label}</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    maxLength={250}
                    name='email'
                    onChange={getTheEmail}
                    placeholder=''
                  />
                  {errEmail && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errEmail}
                    </span>
                  )}
                </Col>
                <Col className='col-width'>
                  <label className='patient-info-title required'>{inputText.cellNo.label}</label>
                  <PhoneInputCompo contactnoValue='' getTheNumber={getTheCellNO} />
                  {errCellPhone && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errCellPhone}
                    </span>
                  )}
                </Col>
              </Row>

              <Row className='row-width'>
                <Col className='first-Input col-width'>
                  <label className='patient-info-title'>{inputText.homeNo.label}</label>
                  <PhoneInputCompo contactnoValue='' getTheNumber={getTheHomeNo} />
                  {errHomePhone && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errHomePhone}
                    </span>
                  )}
                </Col>

                <Col >
                  <label className='patient-info-title'>{inputText.WorkPhone.label}</label>
                  <div style={{ display: 'flex' }}>
                    <PhoneInputCompo contactnoValue='' getTheNumber={getTheWorkNO} />
                  </div>

                  {errWorkPhone && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errWorkPhone}
                    </span>
                  )}

                </Col>
                {/* <Col className='col-width'>
                  <label className='patient-info-title'>{inputText.ex.label}</label>
                  <input
                    type='number'
                    maxLength={4}
                    value={workExtNo || ''}
                    className='form-control SearchInputbox'
                    onChange={getTheExt}
                  />
                  {errWorkExt && (
                    <span className='form-label errMsg'>
                      {inputText.lastName.ErrMsg}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errWorkExt}
                    </span>
                  )}
                </Col> */}

                <Col className='col-width'></Col>
              </Row>
            </div>

            {/* <div className='patient-info-card'>
          <hr className='lineBottom' />
          <Row className='pmrt'>
            <Col md={12}>
              <label className='patient-info-header'>Insurance Information</label>
              <hr className='lineBottom' />
            </Col>
          </Row>

          <Row className='row-width'>
            <Col className='patient-info-header col-width'>
              <div className='form-group'>
                <label className='patient-info-title'>Insurance Co:</label>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control date-box-height'
                    aria-describedby='basic-addon2'
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text'>
                      <i className='fa-solid fa-up-right-from-square'  onClick={insuranceClickSearchClick}></i>
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col className='col-width'>
              <label className='patient-info-title'>Insurance Name:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='insuranceName'
                placeholder=' '
              />
            </Col>

            <Col className='col-width'>
              <div className='form-group'>
                <label className='patient-info-title'>Health Plan Elg:</label>
                <div className='input-group'>
                  <input
                    type='text'
                    name='healthPlaneElg'
                    className='form-control date-box-height'
                    aria-describedby='basic-addon2'
                    disabled
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text'>
                      <i className='fa-solid fa-up-right-from-square'></i>
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col className='col-width'>
              <label className='patient-info-title'>Eligibility Activation:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='eligibilityActivation'
                placeholder=' '
                // style={{width: '20px'}}
              />
            </Col>
          </Row>

          <Row className='row-width'>
            <Col className='patient-info-header col-width'>
              <div className='form-group'>
                <label className='patient-info-title'>Primary Insured:</label>
                <div className='input-group'>
                  <input
                    type='text'
                    name='primaryinsured'
                    className='form-control date-box-height'
                    aria-describedby='basic-addon2'
                  />
                  <div className='input-group-append'>
                    <span className='input-group-text'>
                      <i className='fa-solid fa-up-right-from-square' onClick={primaryGuarantorClickSearchClick}></i>
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col className='col-width'>
              <label className='patient-info-title'>Insured Relationship:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='insuredRelationship'
                placeholder=' '
              />
            </Col>
            <Col className='col-width'>
              <label className='patient-info-title'>Last Name:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='lastName'
                placeholder=''
              />
            </Col>
            <Col className='col-width'>
              <label className='patient-info-title'>First Name:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='firstName'
                placeholder=' '
              />
            </Col>
          </Row>

          <Row className='row-width'>
            <Col className='patient-info-header col-width'>
              <label className='patient-info-title'>Middle Name:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='middleName'
                placeholder=' '
              />
            </Col>

            <Col style={{width: '22%'}}>
              <label className='patient-info-title'>Subscriber ID:</label>
              <div style={{display: 'flex'}}>
                <input
                  type='text'
                  className='form-control textbox-height'
                  name='subscriberID'
                  placeholder=''
                />
              </div>
            </Col>
            <Col className='col-width'>
              <label className='patient-info-title'>Group No:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='groupNo'
                placeholder=' '
              />
            </Col>

            <Col className='col-width'>
              <label className='patient-info-title'>Plan Name:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='planName'
                placeholder=' '
              />
            </Col>
          </Row>

          <Row className='row-width'>
            <Col className='patient-info-header col-width'>
              <label className='patient-info-title'>Deductible:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='deductible'
                placeholder=' '
              />
            </Col>

            <Col style={{width: '22%'}}>
              <label className='patient-info-title'>Copay:</label>
              <div style={{display: 'flex'}}>
                <input
                  type='text'
                  className='form-control textbox-height'
                  name='copay'
                  placeholder=''
                />
              </div>
            </Col>
            <Col className='col-width'>
              <label className='patient-info-title'>Authorization #:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='authorization'
                placeholder=' '
              />
            </Col>

            <Col className='col-width'>
              <label className='patient-info-title'># Of Visits Authorized:</label>
              <input
                type='text'
                className='form-control textbox-height'
                name='OfvisitsAuthorized'
                placeholder=' '
              />
            </Col>
          </Row>

          <Row className='row-width'>
            <Col className='patient-info-header col-width'>
              <label className='patient-info-title'>Eff. Start Date:</label>
              <input
                type='date'
                className='form-control textbox-height'
                name='effStartDate:'
                placeholder=' '
              />
            </Col>

            <Col style={{width: '22%'}}>
              <label className='patient-info-title'>Eff. End Date:</label>
              <div style={{display: 'flex'}}>
                <input
                  type='date'
                  className='form-control textbox-height'
                  name='effEndDate'
                  placeholder=''
                />
              </div>
            </Col>
            <Col className='col-width'></Col>

            <Col className='col-width'></Col>
          </Row>
        </div> */}

            <div className='patient-info-card'>
              <hr className='lineBottom' />
              <Row className='pmrt'>
                <Col md={12}>
                  <label className='sectionTitle p-2'>Referral</label>
                  {/* <hr className='lineBottom' /> */}
                </Col>
              </Row>

              <Row className='row-width  '>
                <Col className='patient-info-header col-width'>
                  <MultiSelectDropdown
                    options={multieoptions}
                    placeHolder={''}
                    InitialValue={referral}
                    getMultiselectData={getTheRefferal}
                  />

                </Col>

                <Col className='col-width'>

                  {/* </Col>

            <Col className='col-width'> */}

                  {/* </Col>

            <Col className='col-width'> */}

                </Col>
              </Row>

            </div>

            <div className='patient-info-card'>
              <hr className='lineBottom' />
              <Row className='pmrt'>
                <Col md={12}>
                  <label className='sectionTitle p-2'>User Defined Fields</label>
                  {/* <hr className='lineBottom' /> */}
                </Col>
              </Row>

              <Row className='row-width'>
                <Col className='first-Input col-width'>
                  <label className='patient-info-title'>Field 1:</label>
                  <input
                  maxLength={100}
                    type='text'
                    className='form-control SearchInputbox'
                    onChange={gettheField1}
                    name='field1'
                    placeholder=''
                  />
                  {errField1 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errField1}
                    </span>
                  )}

                </Col>

                <Col className='col-width'>
                  <label className='patient-info-title'>Field 2:</label>
                  <input
                    type='text'
                    maxLength={100}
                    className='form-control SearchInputbox'
                    name='field2'
                    onChange={gettheField2}
                    placeholder=''
                  />
                  {errField2 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errField2}
                    </span>
                  )}
                </Col>

                <Col className='col-width'>
                  <label className='patient-info-title'>Field 3:</label>
                  <input
                    type='text'
                    maxLength={100}
                    className='form-control SearchInputbox'
                    name='field3'
                    onChange={gettheField3}

                    placeholder=' '
                  />
                  {errField3 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errField3}
                    </span>
                  )}
                </Col>

                <Col className='col-width'>
                  <label className='patient-info-title'>Field 4:</label>
                  <input
                    type='text'
                    maxLength={100}
                    className='form-control SearchInputbox'
                    name='field4'
                    onChange={gettheField4}
                    placeholder=' '
                  />
                  {errField4 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errField4}
                    </span>
                  )}
                </Col>
              </Row>

              <Row className='row-width'>
                <Col className='first-Input col-width'>
                  <label className='patient-info-title'>Field 5:</label>
                  <input
                    type='text'
                    maxLength={100}
                    className='form-control SearchInputbox'
                    name='field5'
                    onChange={gettheField5}

                    placeholder=' '
                  />
                  {errField5 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errField5}
                    </span>
                  )}
                </Col>

                <Col className='col-width'>
                  <label className='patient-info-title'>Field 6:</label>
                  <input
                    type='text'
                    maxLength={100}
                    className='form-control SearchInputbox'
                    name='field6'
                    onChange={gettheField6}

                    placeholder=' '
                  />
                  {errField6 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errField6}
                    </span>
                  )}
                </Col>
                <Col className='col-width'>   <label className='patient-info-title'>Field 7:</label>
                  <input
                    type='text'
                    maxLength={100}
                    className='form-control SearchInputbox'
                    name='field7'
                    onChange={gettheField7}

                    placeholder=' '
                  />
                  {errField7 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errField7}
                    </span>
                  )}
                </Col>
                <Col className='col-width'>   <label className='patient-info-title'>Field 8:</label>
                  <input
                    type='text'
                    className='form-control SearchInputbox'
                    name='field8'
                    maxLength={100}
                    onChange={gettheField8}

                    placeholder=''
                  />
                  {errField8 && (
                    <span className='form-label errMsg'>
                      {/* {inputText.lastName.ErrMsg} */}
                      <i className="fa-solid fa-triangle-exclamation"></i>
                      &nbsp;
                      {errField8}
                    </span>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer  className='p-2 dialoge'>
      <div className='d-flex gap-2 h-40px '>
       <button className='primarybtnBig'
       onClick={handleSubmit} >
            Add Patient
          </button>
          <button className='tertiarybig' onClick={handelClose}>
            Close
          </button>
          </div>
        </Modal.Footer>
      </Modal >

      {/* Popup */}

      {/* this is not work  ...  */}
      {
        showInsuranceClickPopup && (
          <Modal show={showInsuranceClickPopup} onHide={handleCloseInsurancePlanPopup} size='xl'>
            <Modal.Header closeButton>
              <Modal.Title>Insurance List</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form>

                <Row className='mb-2'>
                  <Col md={2}>
                    <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Insurance Name'
                    >
                      <option value=''>Select Insurance Name</option>
                      <option value='1'>SureGuard Health Insurance</option>
                      <option value='2'>LibertyLife Assurance</option>
                      <option value='3'>SafeHarbor Health Plan</option>
                      <option value='4'>SecureHealth Group</option>
                      <option value='5'>PinnacleCare Insurance</option>
                    </select>
                  </Col>
                  <Col md={2}>
                    <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Select an option'
                    >
                      <option value=''>Start With</option>
                      <option value='1'>Equals To</option>
                      <option value='2'>Contains</option>
                    </select>
                  </Col>
                  <Col md={2}>
                    <input
                      type='text'
                      className='form-control date-box-height'
                      name=''
                      placeholder=' '
                    />
                  </Col>
                  <Col md={2}>

                    <Button
                      className='btn-sm btn-search-form'
                    //onClick={() => handleAddHealthPlanRow()}
                    >
                      Search
                    </Button>


                  </Col>

                  <Col md={4}>

                    <Button
                      className='btn-sm btn-search-form float-left'
                    //onClick={() => handleAddHealthPlanRow()}
                    >
                      Add New
                    </Button>


                  </Col>
                </Row>

              </Form>

              <div>
                <input
                  type='text'
                  className='form-control textbox-height'
                  style={{ width: '30%', float: 'right' }}
                  placeholder='Search ...'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <br></br>
              <div>
                <table className='table table-margin'>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: '#1B283F', color: 'white', width: '10%' }}>
                        &nbsp;&nbsp;
                      </th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>
                        Insurance Name
                      </th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Payer ID</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Address</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>City</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>State</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Zip</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Phone</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Insurance Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentInsuranceCompany.map((insuranceData, index) => (
                      <tr key={index}>
                        <td className='datatable-cell '>
                          <span className='label font-weight-bold label-lg  label-light-danger label-inline '>
                            <label className='search-btn-payer'>
                              <i className='fa fa-link icon-color' aria-hidden='true'></i>
                              &nbsp;&nbsp;Select
                            </label>
                          </span>
                        </td>
                        <td>{insuranceData.insuranceName}</td>
                        <td>{insuranceData.payerID}</td>
                        <td>{insuranceData.address}</td>
                        <td>{insuranceData.city}</td>
                        <td>{insuranceData.state}</td>
                        <td>{insuranceData.zip}</td>
                        <td>{insuranceData.phone}</td>
                        <td>{insuranceData.insuranceType}</td>


                      </tr>
                    ))}

                    {currentInsuranceCompany.length === 0 && (
                      <tr>
                        <td colSpan={9} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                          Record not found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className='pagination-container'>
                  <ReactPaginate
                    pageCount={Math.ceil(insuranceCompanyType.length / insurancePerPage)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName='pagination'
                    activeClassName='active'
                    previousLabel='<' // Set the label for the "Previous" button to "<"
                    nextLabel='>'
                  />
                </div>

                <div className='showing-count'>
                  <p>
                    Showing {indexOfFirstInsuranceCompany + 1} to{' '}
                    {Math.min(indexOfLastInsuranceCompany, insuranceCompanyType.length)} of {insuranceCompanyType.length} entries
                  </p>
                </div>
              </div>

            </Modal.Body>


          </Modal>
        )
      }



      {/* Popup */}
      {
        showPrimaryGuarantorClickPopup && (
          <Modal show={showPrimaryGuarantorClickPopup} onHide={handleCloseprimaryGuarantorPopup} size='xl'>
            <Modal.Header closeButton>
              <Modal.Title>Guarantor / Insured List - [Search Result - Top 100 Records]</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form>

                <Row className='mb-2'>
                  <Col md={2}>
                    <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder=''
                    >

                      <option value='1'>Last Name</option>
                      <option value='2'>Patient ID</option>
                      <option value='3'>Insurance Name</option>

                    </select>
                  </Col>
                  <Col md={2}>
                    <select
                      className='form-select'
                      data-control='select2'
                      data-placeholder='Select an option'
                    >
                      <option value=''>Start With</option>
                      <option value='1'>Equals To</option>
                      <option value='2'>Contains</option>
                    </select>
                  </Col>
                  <Col md={2}>
                    <input
                      type='text'
                      className='form-control date-box-height'
                      name=''
                      placeholder=' '
                    />
                  </Col>
                  <Col md={2}>

                    <Button
                      className='btn-sm btn-search-form'
                    //onClick={() => handleAddHealthPlanRow()}
                    >
                      Search
                    </Button>


                  </Col>

                  <Col md={4}>

                    <Button
                      className='btn-sm btn-search-form float-left'
                    //onClick={() => handleAddHealthPlanRow()}
                    >
                      Add New
                    </Button>


                  </Col>
                </Row>

              </Form>

              <div>
                <input
                  type='text'
                  className='form-control textbox-height'
                  style={{ width: '30%', float: 'right' }}
                  placeholder='Search ...'
                  value={searchPrimaryGuarantorQuery}
                  onChange={(e) => setSearchPrimaryGuarantorQuery(e.target.value)}
                />
              </div>
              <br></br>
              <div>
                <table className='table table-margin'>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: '#1B283F', color: 'white', width: '10%' }}>
                        &nbsp;&nbsp;
                      </th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>
                        Patient ID
                      </th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Pat.Acct.No</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Name</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Home Phone</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Date Of Birth</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Sex</th>
                      <th style={{ backgroundColor: '#1B283F', color: 'white' }}>Insurance Name</th>

                    </tr>
                  </thead>
                  <tbody>
                    {currentPrimaryGuarantor.map((primaryGuarantor, index) => (
                      <tr key={index}>
                        <td className='datatable-cell '>
                          <span className='label font-weight-bold label-lg  label-light-danger label-inline '>
                            <label className='search-btn-payer'>
                              <i className='fa fa-link icon-color' aria-hidden='true'></i>
                              &nbsp;&nbsp;Select
                            </label>
                          </span>
                        </td>
                        <td>{primaryGuarantor.patientID}</td>
                        <td>{primaryGuarantor.patAcctNo}</td>
                        <td>{primaryGuarantor.name}</td>
                        <td>{primaryGuarantor.homePhone}</td>
                        <td>{primaryGuarantor.dateOfBirth}</td>
                        <td>{primaryGuarantor.sex}</td>
                        <td>{primaryGuarantor.insuranceName}</td>



                      </tr>
                    ))}

                    {currentPrimaryGuarantor.length === 0 && (
                      <tr>
                        <td colSpan={8} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                          Record not found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className='pagination-container'>
                  <ReactPaginate
                    pageCount={Math.ceil(primaryGuarantorData.length / primaryGuarantorPerPage)}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName='pagination'
                    activeClassName='active'
                    previousLabel='<' // Set the label for the "Previous" button to "<"
                    nextLabel='>'
                  />
                </div>

                <div className='showing-count'>
                  <p>
                    Showing {indexOfFirstPrimaryGuarantor + 1} to{' '}
                    {Math.min(indexOfLastPrimaryGuarantor, primaryGuarantorData.length)} of {primaryGuarantorData.length} entries
                  </p>
                </div>
              </div>

            </Modal.Body>


          </Modal>
        )
      }


    </>
  )
}

export default AddNewPatient


import React, { useEffect, useRef, useState } from 'react'
import inputText from '../../../../ReUsableComponent/ReyClinicalinputs.json'
import TagsInput from '../../../../ReUsableComponent/TagsInput'
import { Modal } from 'react-bootstrap'
import SearchableDropdown from '../../ReusablePages/ReusableAppointment/SearchableDropdown'
import { groupIdRegExp } from '../../../../ReUsableComponent/regEx'
import ModalImage from 'react-modal-image';
import iIcon from '../../EHRIcon/infoIcon.svg'
import axios from 'axios'
import { toast } from 'react-toastify'
import ErrorMsgShow from '../../../../ReUsableComponent/ErrorMsgShow'
import { useParams } from 'react-router-dom'
import moment from 'moment'


function EditDocument({ open, closeDocumentPopUp, patientData, disabled , documentId }) {
  const APIkey = process.env.REACT_APP_SERVERAPI;

    const {appointmentId } = useParams()

  
    


    const inputRef = useRef(null);

    
    const maxSizeInBytes =  10 * 1024 * 1024;
    const allowedExtensions = ['.jpeg', '.jpg', '.png'];
    const fileLimit = null ; 

    const [loading, setLoading] = useState<any>(false)

    const [clinicId, setClinicId] = useState<any>()
    const [provideName, setProviderName] = useState<any>([])
    const [mrn, setMrn] = useState()


    const [attachFile, setAttachFile] = useState("")
    const [attachFileName, setAttachFileName] = useState<any>([])

  
    
    const [attachFileErr, setAttachFileErr] = useState("")

    const [patientId, setPatientId] = useState<any>()

    const [patientName, setPatientName] = useState<any>()

    const [visitDate, setVisitDate] = useState<any>()
    const [visitTime, setVisitTime] = useState<any>()
    const [documentName, setDocumentName] = useState<any>()
    const [description, setDescription] = useState<any>()
    const [documentTags, setDocumentTags] = useState<any>([])
    const [folderList, setFolderList] = useState<any>([])

    const [documentTypeList, setDocumentTypeList] = useState<any>([])

    const [advanceDirectiveList, setAdvanceDirectiveList] = useState([])


    const [statusList, setStatusList] = useState([])

    const [folderId, setFolderId] = useState<any>()

    const [providerId, setProviderId] = useState<any>()
    const [documentTypeId, setDocumentTypeId] = useState<any>()
    const [advanceDirectiveId, setAdvanceDirectiveId] = useState<any>()
    const [status, setStatus] = useState<any>()
    const [attachment, setAttachment] = useState<any>([])

    const [sendToReview, setSendToReview] = useState<any>(false)

    const [uploadedBy, setUploadedBy] = useState<any>()

    const [openCreateFolder, setOpenCreateFolder] = useState<any>(false)
    const [folderName, setFolderName] = useState<any>()



    const [documentNameErr, setDocumentNameErr] = useState<any>()
    const [folderIdErr, setFolderIdErr] = useState<any>()
    const [patientNameErr , setPatientNameErr ] = useState<any>()
    const [attachmentBasicErr , setAttachmentBasicErr ] = useState<any>()

    const [folderNameErr, setFolderNameErr] = useState<any>()

    const [documentTypeErr , setDocumentTypeErr] = useState<any>()


    useEffect(() => {
        const getThePatientInsuranceTypeList = async () => {
            try {
                const getloginInfo = localStorage.getItem('appLoginInfo');
        
                if (getloginInfo !== null) {
                    const loginInfo = JSON.parse(getloginInfo);
                    const Token = loginInfo.data.accessToken;
                    const Id = loginInfo.data.clinicId;
                    const userId = loginInfo.data.id;
                    const header = {
                        headers: { 'x-access-token': Token }
                    };
        
                    setClinicId(Id);
        
                    if (patientData?._id) {
                        setPatientId(patientData._id);
                    }
        
                    await fetchFolderList(Id, header);
                    await fetchDocumentTypeList(Id, header);
                    await fetchAdvanceDirectiveList(Id, header);
                    await fetchDocumentStatusList(Id, header);
                    await fetchUploadedBy(Id, userId, header);
                    await fetchPatientDocumentsById(Id, documentId, header);
        
                    if (disabled) {
                        setPatientName(`${patientData?.firstName} ${patientData?.lastName}`);
                        setMrn(patientData?.mrn);
        
                        if(appointmentId != "null"){
                            await fetchAppointmentDataById(appointmentId, header);
                        }
                    }
                } else {
                    console.log("No login info found in localStorage");
                }
            } catch (err) {
                console.log("Err:", err);
            }
        };
        
        getThePatientInsuranceTypeList();
    }, [openCreateFolder]);
    
    const fetchFolderList = async (Id, header) => {
        try {
            const response = await axios.get(`${APIkey}/api/admin/getFolderList`, header);
            setFolderList(response?.data?.data?.map(i => ({
                label: i.folderName,
                value: i.folderName,
                id: i._id
            })));
        } catch (err) {
            console.log(err);
        }
    };
    
    const fetchDocumentTypeList = async (Id, header) => {
        try {
            const response = await axios.get(`${APIkey}/api/admin/getDocumentTypeList`, header);
            setDocumentTypeList(response?.data?.data?.map(i => ({
                label: i.documentTypeName,
                value: i.documentTypeName,
                id: i._id
            })));
        } catch (err) {
            console.log(err);
        }
    };
    
    const fetchAdvanceDirectiveList = async (Id, header) => {
        try {
            const response = await axios.get(`${APIkey}/api/admin/getAdvanceDirectiveList`, header);
            setAdvanceDirectiveList(response?.data?.data?.map(i => ({
                label: i.advanceDirectiveName,
                value: i.advanceDirectiveName,
                id: i._id
            })));
        } catch (err) {
            console.log(err);
        }
    };
    
    const fetchDocumentStatusList = async (Id, header) => {
        try {
            const response = await axios.get(`${APIkey}/api/admin/getDocumentStatusList`, header);
            setStatusList(response?.data?.data?.map(i => ({
                label: i.documentStatusName,
                value: i.documentStatusName,
                id: i._id
            })));
        } catch (err) {
            console.log(err);
        }
    };
    
    const fetchUploadedBy = async (Id, userId, header) => {
        try {
            const response = await axios.get(`${APIkey}/api/admin/getUserById/${Id}/${userId}`, header);
            const uploadedBy = `${response?.data?.data?.firstName} ${response?.data?.data?.lastName}`;
            setUploadedBy(uploadedBy);
        } catch (err) {
            console.log(err);
        }
    };
    
    const fetchPatientDocumentsById = async (Id, documentId, header) => {
        try {
            const response = await axios.get(`${APIkey}/api/admin/getPatientDocumentsById/${documentId}`, header);
            const getByIdDocuments = response?.data?.data;
    
            if (getByIdDocuments) {
                setDocumentName(getByIdDocuments?.documentName);
                setDescription(getByIdDocuments?.description);
                setDocumentTags(getByIdDocuments?.documentTags);
                setFolderId(getByIdDocuments?.folderId);
                setDocumentTypeId(getByIdDocuments?.documentTypeId);
                setAdvanceDirectiveId(getByIdDocuments?.advanceDirectiveId);
                setStatus(getByIdDocuments?.status);
                setAttachment(getByIdDocuments?.attachment);
                setAttachFileName(getByIdDocuments?.attachment?.map(item => {
                    const extractFilename = url => {
                        const parts = url.split('/');
                        return parts[parts.length - 1];
                    };
                    return {
                        link: item,
                        name: extractFilename(item)
                    };
                }));
                setSendToReview(getByIdDocuments?.sendToReview);
            }
        } catch (err) {
            console.log(err);
        }
    };
    
    const fetchAppointmentDataById = async (appointmentId, header) => {
        try {
            const response = await axios.get(`${APIkey}/api/admin/getAppointmentById/${appointmentId}`, header);
            const appointmentData = response?.data?.data;
            if (appointmentData) {
                setVisitDate(moment(appointmentData?.appointmentDate).format('YYYY-MM-DD'));
                setVisitTime(appointmentData?.appointmentTime);
                setProviderName([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    
    

    const handelClose = () => {
        closeDocumentPopUp()
    }



    const addFolder = async (data) => {
        try {
            const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

            if (getloginInfo !== null) {
                const Token = JSON.parse(getloginInfo).data.accessToken;
                const header = {
                    headers: { 'x-access-token': Token },
                };

                console.log(header);

                const response = await axios.post(`${APIkey}/api/admin/createFolder`, data, header);
                console.log('this is response ', response);

                if (response) {

                    setOpenCreateFolder(false)

                    toast.success('Folder added successfully!', {
                        position: 'top-right'
                    });
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const submitFolderName = (e) => {

        if (e.key === 'Enter' || e.key === 'Tab') {

            e.preventDefault();
            
            let data = {
                folderName
            }

            if (!folderName || folderName.trim() === '') {

                setFolderNameErr("Please enter a folder name");

            } else {

                addFolder(data);
            }
        }
    }




    const handleAttachFile = (e) => {
        const files = e.target.files;
        const fileArray: any = [];

        const filesName: any = [];



        // Loop through each selected file
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();

            reader.onload = () => {
                // Convert the file to base64 and push it to the fileArray
                fileArray.push(
                    // name: files[i],
                    reader.result
                );

                filesName.push(files[i]);


                // If all files are processed, you can now use the fileArray
                if (fileArray.length === files.length) {
                    setAttachment([...attachment, ...fileArray]);

                    setAttachFileName([...attachFileName, ...filesName]);


                    // Here, you can do whatever you want with the fileArray
                }
            };

            // Read the selected file as data URL
            reader.readAsDataURL(files[i]);
        }
    };


    const deleteTag =(index)=> {
        setAttachment(attachment.filter((_, i) => i !== index));
        setAttachFileName(attachFileName.filter((_, i) => i !== index));
    }


    const validation = (value: any) => {

        const error: any = {};




        if (!value.documentName || value.documentName.trim() == '') {
            error.documentName = inputText.documentName.ErrMsg;
        }


        if(!value.patientId){
           error.patientId = inputText.patientName.ErrMsg ; 
        }
        // if (value.planId) {
        //     if (!groupIdRegExp.test(value.planId)) {
        //         error.planId = inputText.PlanID.validErrMsg;
        //     }
        // }

        if (!value.folderId) {
            error.folderId = inputText.folderName.ErrMsg
        }


        // if (!value.layerOfCoverage) {
        //     error.layerOfCoverage = inputText.layerofCoverage.ErrMsg;
        // }



        if(!value.documentTypeId){
            error.documentTypeId  = inputText.documentType.ErrMsg
        }


        if(!value.attachment.length){
            error.attachment = "Please enter attachment(s)"
        }
        else{
                if( fileLimit && value?.attachment?.length >= fileLimit){

                    error.attachment = `${fileLimit} attachment(s) Limit`
                }  
        }
        // if (value.attachments_front) {
        //     if (value.attachments_front.size > maxSizeInBytes) {
        //         error.attachments_front = inputText.UploadInsuranceCard.ErrMsg
        //     }
        //     else {
        //         const allowedExtensions = ['.jpeg', '.jpg', '.png'];
        //         const fileExtension = value.attachments_front.name.toLowerCase().slice(value.attachments_front.name.lastIndexOf('.'));
        //         if (!allowedExtensions.includes(fileExtension)) {
        //             error.attachments_front = 'Please upload a valid file type (.jpeg, .jpg, or .png)';
        //         }
        //     }
        // }

        // if (value.attachments_back) {
        //     if (value.attachments_back.size > maxSizeInBytes) {
        //         error.attachments_back = inputText.UploadInsuranceCard.ErrMsg
        //     }
        //     else {
        //         const allowedExtensions = ['.jpeg', '.jpg', '.png'];
        //         const fileExtension = value.attachments_back.name.toLowerCase().slice(value.attachments_back.name.lastIndexOf('.'));
        //         if (!allowedExtensions.includes(fileExtension)) {
        //             error.attachments_back = 'Please upload a valid file type (.jpeg, .jpg, or .png)';
        //         }
        //     }
        // }

        return error

    }


    const submitData = async (data) => {
        try {
            const getloginInfo: string | null = localStorage.getItem('appLoginInfo');

            if (getloginInfo !== null) {
                const Token = JSON.parse(getloginInfo).data.accessToken;
                const Id = JSON.parse(getloginInfo).data.clinicId;
                const header = {
                    headers: { 'x-access-token': Token },
                    // 'Content-Type': 'multipart/form-data'
                };

                const response = await axios.post(`${APIkey}/api/admin/updatePatientDocuments/${documentId}`, data, header);
                console.log('this is response ', response);

                if (response) {
                    handelClose();

                    setLoading(false)

                    toast.success('Document updated successfully!', {
                        position: 'top-right'
                    });
                }
            }
        }
        catch (err) {
            console.log("error occur : ", err);
        }
    }

    const EditDocument = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true)

        let data = {

            patientId,
            visitDate,
            visitTime,
            providerId,
            documentName,
            description,
            documentTags,
            folderId,
            documentTypeId,
            advanceDirectiveId,
            status,
            attachment,
            sendToReview
        }

        console.log("data:", data);


        let err = validation(data)

        console.log("error", err);


        if (Object.keys(err).length === 0) {

            submitData(data)
          
            setDocumentNameErr(err.documentName)
            setFolderIdErr(err.folderId)
            setPatientNameErr(err.patientId) 
            setAttachmentBasicErr(err.attachment)
            setDocumentTypeErr(err.documentTypeId)

      
        }
        else {
            setLoading(false)
            
            setDocumentNameErr(err.documentName)
            setFolderIdErr(err.folderId)
            setPatientNameErr(err.patientId) 
            setAttachmentBasicErr(err.attachment)
            setDocumentTypeErr(err.documentTypeId)
            
        }
    }




    return (
        <Modal
            show={open}
            onHide={handelClose}
            className=''
            size='xl'
            centered
        >
            <Modal.Header closeButton className='dialoge p-3'>
                <Modal.Title >Edit Documents</Modal.Title>
            </Modal.Header>
            <form onSubmit={EditDocument} className=''>

                <Modal.Body className=' dialoge p-1'>
                    <div className='card p-3'>
                        <div className='row'>
                            <div className='col-md-6 col-lg-4 '>
                                <label className='form-label required patient-info-title' htmlFor="">{inputText.patientName.label}</label>
                                {
                                disabled ? 
                                <input
                                    type="text"
                                    value={patientName}
                                    // onChange={getTheBin}
                                    disabled={disabled}
                                    placeholder={inputText.patientName.placeholder}
                                    className='form-control SearchInputbox' />
                                    : 
                                    <SearchableDropdown
                                     />
                                }
                                <ErrorMsgShow errorMsg={patientNameErr} />

                         
                            </div>
                            <div className='col-md-6 col-lg-4'>
                                <label className='form-label required patient-info-title' htmlFor="">{inputText.mrn.label}</label>
                                <input
                                    type="number"
                                    disabled
                                    value={mrn}
                                    // onChange={getTheBin}
                                    placeholder={inputText.mrn.placeholder}
                                    className='form-control SearchInputbox' />
                               
                            </div>
                            <div className='col-md-6 col-lg-4 '>
                                <label className='form-label patient-info-title' htmlFor="">{inputText.visitDate.label}</label>
                                <input
                                    type="date"
                                    disabled={disabled ? true : false}
                                    value={visitDate}
                                    // value  ={pcn}
                                    // onChange={getThePcn}
                                    placeholder={inputText.visitDate.placeholder}
                                    className='form-control SearchInputbox'
                                />

                            </div>

                        </div>
                        <div className='row mt-2 '>

                            <div className='col-md-6 col-lg-4'>
                                <label className='form-label  patient-info-title' htmlFor="">{inputText.visitTime.label}</label>
                                <input
                                    type="time"
                                    disabled={disabled}
                                    value={visitTime}
                                  
                                    placeholder={inputText.visitTime.placeholder}
                                    // onChange={getTheInsurancePlan}
                                    className='form-control SearchInputbox ' />
                                {
                                    // errInsurancePlanName &&

                                    // <span className='form-label errMsg'>
                                    //     <i className="fa-solid fa-triangle-exclamation"></i>
                                    //     &nbsp;
                                    //     {/* {inputText.InsurancePlaneName.ErrMsg} */}
                                    //     {errInsurancePlanName}
                                    // </span>
                                }
                            </div>
                            <div className='col-md-6 col-lg-4 '>
                                <label className='form-label  patient-info-title' htmlFor="">{inputText.Provider.label}</label>
                    {
                        disabled ?
                        <input type="text"
                        disabled
                        value={provideName}
                         className="form-control SearchInputbox " />
                         : 
                                <SearchableDropdown
                                    // InitialValue={coverageType}
                                    // required={true}

                                    // gettingData={getTheCoverageType}
                                    placeHolder={inputText.Provider.placeholder}
                                // options={coverageTypeData}
                                />

                            }


                            </div>
                            <div className='col-md-6 col-lg-4 '>
                                <label className='form-label required patient-info-title' htmlFor="">{inputText.documentName.label}</label>
                                <input
                                    // maxLength={10}
                                    type="text"
                                    onChange={(e) => setDocumentName(e.target.value)}
                                    maxLength={100}
                                    value={documentName}
                                    placeholder={inputText.documentName.placeholder}
                                    className='form-control SearchInputbox'
                                />
                                <ErrorMsgShow errorMsg={documentNameErr} />
                            </div>



                        </div>
                        <div className='row mt-2 '>

                            <div className='col-md-6 col-lg-4'>
                                <label className='form-label  patient-info-title ' htmlFor="">{inputText.description.label}</label>
                                <input
                                    type="text"
                                    maxLength={500}
                                    value={description}
                                    placeholder={inputText.description.placeholder}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className='form-control SearchInputbox ' />

                                {
                                    // errEffectiveDate &&

                                    // <span className='form-label errMsg'>
                                    //     <i className="fa-solid fa-triangle-exclamation"></i>
                                    //     &nbsp;
                                    //     {errEffectiveDate}
                                    // </span>
                                }

                            </div>
                            <div className='col-md-6 col-lg-4 '>
                                <label className='form-label  patient-info-title' htmlFor="">{inputText.documentTags.label}</label>
                                <TagsInput
                                    duplicate={false}
                                    max={5}
                                    tagsValue={documentTags}
                                    getTheTags={(e) => setDocumentTags(e)
                                    }
                                />
                                {
                                    // errEndDate &&

                                    // <span className='form-label errMsg ' >
                                    //     <i className="fa-solid fa-triangle-exclamation"></i>
                                    //     &nbsp;
                                    //     {errEndDate}
                                    // </span>
                                }

                            </div>
                            <div className='col-md-6 col-lg-4 '>

                                {
                                    openCreateFolder ?
                                        (

                                            <>

                                                <label className='form-label  patient-info-title ' htmlFor="">Create New Folder:</label>
                                                <div className='d-flex gap-2 '>
                                                    <input
                                                        autoFocus
                                                        ref={inputRef}
                                                        onBlur={() => [setOpenCreateFolder(false), setFolderNameErr('')]}
                                                        onChange={(e) => setFolderName(e.target.value)}
                                                        onKeyDown={submitFolderName}

                                                        type='text'
                                                        className='form-control SearchInputbox'
                                                    />
                                                </div>
                                                {
                                                    folderNameErr && (
                                                        <>
                                                            <span className='form-label errMsg'>
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                                &nbsp;
                                                                {folderNameErr}
                                                            </span>
                                                        </>
                                                    )
                                                }
                                            </>



                                        )

                                        : (

                                            <>
                                                <label className='form-label required patient-info-title ' htmlFor="">{inputText.folderName.label}</label>
                                                <div className='d-flex gap-2 '>
                                                    <div className='w-100'>
                                                        <SearchableDropdown
                                                            InitialValue={folderId}
                                                           
                                                            gettingData={(e) => setFolderId(e)}
                                                            placeHolder={inputText.folderName.placeholder}
                                                            options={folderList}
                                                        />
                                                    </div>
                                                    <div>
                                                        <button className='blueButton' onClick={() => setOpenCreateFolder(true)}>
                                                            <i className="fa-solid fa-plus "></i>
                                                        </button>
                                                    </div>

                                                </div>
                                                <ErrorMsgShow errorMsg={folderIdErr} />
                                            </>
                                        )


                                }


                                {
                                    // errPlanId &&

                                    // <span className='form-label errMsg ' >
                                    //     <i className="fa-solid fa-triangle-exclamation"></i>
                                    //     &nbsp;
                                    //     {/* {inputText.InsuranceEndDate.ErrMsg} */}
                                    //     {errPlanId}
                                    // </span>
                                }

                            </div>


                        </div>
                        <div className='row mt-2 '>

                            <div className='col-md-6 col-lg-4'>
                                <label className='form-label  patient-info-title required' htmlFor="">{inputText.documentType.label}</label>
                                <SearchableDropdown
                                    InitialValue={documentTypeId}
                                    // required={true}
                                    gettingData={(e) => setDocumentTypeId(e)}
                                    placeHolder={inputText.documentType.placeholder}
                                    options={documentTypeList}
                                />
                                 <ErrorMsgShow errorMsg={documentTypeErr} />


                                {
                                    // errEffectiveDate &&

                                    // <span className='form-label errMsg'>
                                    //     <i className="fa-solid fa-triangle-exclamation"></i>
                                    //     &nbsp;
                                    //     {errEffectiveDate}
                                    // </span>
                                }

                            </div>
                            <div className='col-md-6 col-lg-4 '>
                                <label className='form-label  patient-info-title' htmlFor="">{inputText.typesofAdvancedDirectives.label}</label>
                                <SearchableDropdown
                                    InitialValue={advanceDirectiveId}
                                    // required={true}
                                    gettingData={(e) => setAdvanceDirectiveId(e)}
                                    placeHolder={inputText.typesofAdvancedDirectives.placeholder}
                                    options={advanceDirectiveList}
                                />

                                {
                                    // errEndDate &&

                                    // <span className='form-label errMsg ' >
                                    //     <i className="fa-solid fa-triangle-exclamation"></i>
                                    //     &nbsp;
                                    //     {errEndDate}
                                    // </span>
                                }

                            </div>
                            <div className='col-md-6 col-lg-4 '>
                                <label className='form-label  patient-info-title' htmlFor="">{inputText.uploadedBy.label}</label>
                                <input
                                    type="text"
                                    disabled
                                    // maxLength={15}
                                    placeholder={inputText.uploadedBy.placeholder}
                                    // onChange={getThePlanId}
                                    value={uploadedBy}
                                    className='form-control SearchInputbox' />
                                {
                                    // errPlanId &&

                                    // <span className='form-label errMsg ' >
                                    //     <i className="fa-solid fa-triangle-exclamation"></i>
                                    //     &nbsp;
                                    //     {/* {inputText.InsuranceEndDate.ErrMsg} */}
                                    //     {errPlanId}
                                    // </span>
                                }

                            </div>


                        </div>
                        <div className='row mt-2 '>

                            <div className='col-md-6 col-lg-4'>
                                <label className='form-label patient-info-title' htmlFor="">{inputText.status.label}</label>
                                <SearchableDropdown
                                    InitialValue={status}
                                    // required={true}
                                    gettingData={(e) => setStatus(e)}
                                    placeHolder={inputText.status.placeholder}
                                    options={statusList}
                                />
                                {
                                    // errLayerOfCoverage &&

                                    // <span className='form-label errMsg'>
                                    //     <i className="fa-solid fa-triangle-exclamation"></i>
                                    //     &nbsp;
                                    //     {inputText.layerofCoverage.ErrMsg}
                                    // </span>
                                }
                                {
                                    // alreadyExistInsuranceErr &&

                                    // <span className='form-label errMsg'>
                                    //     <i className="fa-solid fa-triangle-exclamation"></i>
                                    //     &nbsp;
                                    //     {alreadyExistInsuranceErr}
                                    // </span>
                                }
                            </div>
                            <div className=' col-md-12 col-lg-4'>
                                <label htmlFor="" className='patient-info-title d-flex align-items-center   required'>Attach File(s):</label>

                                {/* <input type="file" onChange={handleFrontAttachmentChange} accept="image/*" /> */}
                                {/* <input type="file" onChange={handleBackAttachmentChange} accept="image/*" /> */}
                                <div className=''>


                                    <div  className={attachFileErr ? "uploadErrbtn mx-2 d-flex align-items-center justify-content-center" : ' uploadbtn mx-2 d-flex align-items-center justify-content-start patient-info-title'} >
                                        {attachFileName.length ?
                                            <div className='w-100'>
                                                <label htmlFor="upload-photo1" style={{ color: "#0027FF", textDecoration: "underLine", cursor: "pointer" }}>
                                                    browse
                                                </label>
                                                <div className='documentFilesContainer' style={{overflow :"hidden", maxHeight : '100px' , overflowY:"auto" }}>

                                                {
                                                    attachFileName?.map((i: any, index) => {

                                                        let err = false;
                                                        if(i.size >= maxSizeInBytes ) {
                                                            err = true;
                                                        }else{
                                                            const fileExtension = i?.name?.toLowerCase()?.slice(i?.name?.lastIndexOf('.'));
                                                            if(!allowedExtensions.includes(fileExtension)){
                                                                err = true; 
                                                            }
                                                        }


                                                        return (
                                                            <div key={index} 
                                                           
                                                            className="documentFiles my-1">
                                                            <span className="documentFileName">
                                                                {i?.name}
                                                            </span>
                                                                <span className='documentFileCancelBtn' style={{cursor :"pointer" }} onClick={() => deleteTag(index)}>
                                                                    &times;
                                                                </span>
                                                            </div>

                                                        )
                                                    })

                                                }
                                                </div>

                                                {/* this is attachFileName */}
                                                <span> </span>
                                            </div>
                                            :
                                            <div>
                                                <i className="fa fa-cloud-upload mx-2" aria-hidden="true"></i>
                                                Drop file to attach, or &nbsp;
                                                <label htmlFor="upload-photo1" style={{ color: "#0027FF", textDecoration: "underLine", cursor: "pointer" }}>
                                                    browse
                                                </label>
                                            </div>
                                        }
                                        <input
                                            type="file"
                                            onChange={handleAttachFile}
                                            multiple
                                            id='upload-photo1' accept="image/*" style={{ display: 'none' }} />
                                    </div>
                                        <ErrorMsgShow errorMsg={attachmentBasicErr} />

                                </div>
                                {/* <div className='col-md-2 col-lg-1 form-control' style={{ width: '50px', height: '50px' }}>
                            <ModalImage
                                // small={att_front_image}
                                // large={att_front_image}
                                alt=""
                                hideDownload={true} // Optionally hide download button
                                hideZoom={true}     // Optionally hide zoom button
                            />
                        </div> */}

                            </div>
                            <div className=' col-md-12 col-lg-4 py-7 '>
                                <div className='d-flex align-item-center gap-2'>

                                    <input
                                        type="checkbox"
                                        className='my-1'
                                        name=""
                                        checked={sendToReview}
                                        onChange={() => setSendToReview(!sendToReview)}
                                        id="stdfr" />

                                    <label htmlFor='stdfr'>Send To Do for Review</label>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='dialoge d-flex justify-content-end gap-2 p-2'>
                        {
                            loading ?
                                <div className='loader'></div>
                                :
                                <>
                                    {

                                        !openCreateFolder && (
                                            <button
                                                className='primarybtnBig'
                                                type='submit'
                                            >
                                                Save
                                            </button>
                                        )

                                    }

                                </>

                        }

                        <button
                            className='tertiarybig'
                            onClick={handelClose}
                        >
                            Close
                        </button>
                    </div>

                </Modal.Body>

            </form>

            <Modal.Footer className='dialoge p-1'>

            </Modal.Footer>

        </Modal>
    )
}

export default EditDocument
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import TitleComponent from './TitleComponent';
import {CommonbigButton} from '../SuperAdmin/Pages/ReausableComponent/CommonButton';

function DeleteModel({openDelete, closedelete, deleteID, deleteFunction, deletedEntity}) {
  const handleClosedelete = () => {
    closedelete();
  };

  const hadleDelete = (id) => {
    // console.log("this is handle Delete function ... ", id);
    deleteFunction(id);
  };

  return (
    <Modal show={openDelete} onHide={handleClosedelete} size='lg' centered>
      <Modal.Header closeButton style={{background: '#F2F3F7'}} className='py-1 px-3'>
        <Modal.Title className='px-0'>
          {' '}
          <TitleComponent type='title' className='px-1 '>
            Delete Confirmation
          </TitleComponent>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='fs-4 px-3 py-1 fw-bold dialoge'>
        <div className='card p-3 sectionTitle'>
          Are you sure you want to delete {deletedEntity} ?
        </div>
      </Modal.Body>

      <Modal.Footer className='p-2 dialoge'>
        <div className='d-flex gap-2 '>
          <CommonbigButton onClick={() => hadleDelete(deleteID)}>Delete</CommonbigButton>
          <CommonbigButton background={'#D7D7D7'} color={'#818181'} onClick={handleClosedelete}>
            Close
          </CommonbigButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModel;

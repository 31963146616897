import {FC} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {checkIsActive, KTIcon} from '../../../../_metronic/helpers'
import TitleComponent from '../../../../ReUsableComponent/TitleComponent'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
}


const MenuItem: FC<Props> = ({to, title, icon, fontIcon, hasArrow = false, hasBullet = false}) => {
  
  const {pathname} = useLocation()

  
  

  return (
    // this is MenuList 
    <div className='menu-item me-lg-1 fs-bold ' >

      <Link
        className={clsx('menu-link py-1', {
          'active menu-here  ' : checkIsActive(pathname, to),
          
         }
        )}
        
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

        <span className=''style={{ letterSpacing: '1px' }}><TitleComponent type='header' className= {checkIsActive(pathname, to) ? "text-black" : 'text-white'}   >{title}</TitleComponent></span>

        {hasArrow && <span className='menu-arrow'></span>}

      </Link>
    </div>
  )
}

export {MenuItem}
